#contact {
  padding: 50px 0;
  background: #1f1f1f;

  .divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    span {
      height: 1px;
      width: 150px;
      background: #4244a5;

      &.middle-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin: 0 15px;
      }
    }
  }

  .contact-container {
    text-align: center;
  }

  .contact-button {
    padding: 15px 30px;
    font-size: 1.1rem;
    background: transparent;
    color: #4244a5;
    border: 2px solid #4244a5;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: #4244a5;
      color: white;
      transform: translateY(-2px);
    }
  }
}
