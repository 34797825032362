@import "../src/scss/themes/theme-dark.scss";
@import "../src/scss/themes/theme-light.scss";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap");

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-container {
  height: 50px;
}

.title-styles {
  font-family: "Raleway", sans-serif;
  font-size: 250%;
}

.header-icon {
  height: 150px;
  width: 150px;
}

header {
  position: relative;
  height: 520px;
  min-height: 450px;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  text-align: center;
  overflow: hidden;
  background-color: #d4d5f74f;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 0;
  transition: all 0.5s ease-in-out;
}

.language-icon {
  font-size: 50px;
  cursor: pointer;
}

.project-date {
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 8px 15px 2px 15px;
  position: relative;
  z-index: -500;
  border-top: 5px solid #696969;
  border-radius: 0 0 5px 5px;
  background-color: #696969;
  color: white;
}

.skills-tile {
  background-color: transparentize(#a1b7e9, 0.95);
  padding: 10px 10px 5px 10px;
  width: 100px;
  margin: 5px 0 5px 0;
  border-radius: 8px;
}

.language {
  font-size: 25px;
  background-color: #d4d5f74f;
  padding-bottom: 10px;
  padding-top: 80px;
  position: relative;
}

header h1 {
  font-size: 400%;
  text-align: center;
  font-weight: 600 !important;
  color: #353239;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin: 0 auto 18px auto;
  width: 100%;
}

.slider-image {
  border: 5px solid #d4d5f74f;
}

.slider-tab {
  background-color: #d4d5f74f;
  height: 25px;
}

.slider-iconfiy {
  margin-top: 10px;
}

.styles_typicalWrapper__1_Uvh::after {
  cursor: none !important;
  display: none;
}

#about {
  background: #fff;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: linear-gradient(to top, #d4d5f74f 0%, transparent 100%);
    z-index: 1;
  }
}

#about h1 {
  padding-top: 5%;
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: black;
  text-align: center;
}

.foto img {
  display: block;
  width: 100%;
}

.foto div {
  background: #ffffff;
  display: inline-block;
  margin: 0 auto 5% auto;
  padding: 10px 10px 5px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 0;
  position: relative;
}

.foto div:after {
  color: #333;
  font-size: 25px;
  content: attr(title);
  position: relative;
  top: 15px;
}

.foto div:hover {
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  transform: scale(1.01);
  z-index: 10;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
}

.project-title-settings {
  margin-top: 5%;
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: black;
  text-align: center;
}

.polaroid img {
  display: block;
  max-width: 200px;
}

.polaroid span {
  background: #ffffff;
  display: inline-block;
  margin: 55px 75px 30px;
  padding: 15px 15px 30px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 0;
  position: relative;
}

.link-href {
  color: black;
}

.wave {
  font-size: 160%;
}

.font-trebuchet {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

#preview {
  width: 500px;
  background-color: #d4d5f74f;
  padding: 15px;
  position: relative;
  margin-bottom: 15%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#resume {
  background: #d4d5f74f;
}

.experience-icon {
  font-size: 300%;
  margin-top: 25%;
  text-align: center;
}

.main-badge {
  font-size: 13px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #d5a1e951 !important;
  color: white;
  font-weight: lighter !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.experience-badge {
  font-size: 11px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #d4d5f74f !important;
  color: black;
  font-weight: lighter !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.vertical-timeline-element-date {
  padding: 0 !important;
  margin: 0 !important;
}

@media only screen and (max-width: 1170px) {
  .experience-icon {
    font-size: 170%;
    margin-top: 27%;
    text-align: center;
  }
}

.modal-inside .modal-content {
  background: white;
}

.bars {
  width: 95%;
  float: left;
  padding: 0;
  text-align: left;
}

.bars .skills {
  margin-top: 36px;
  list-style: none;
}

.bars li {
  position: relative;
  margin-bottom: 60px;
  background: #ccc;
  height: 42px;
  border-radius: 3px;
}

.bars li em {
  font: 15px "opensans-bold", sans-serif;
  color: #313131;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  position: relative;
  top: -36px;
}

.bar-expand {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding-right: 24px;
  background: #313131;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  border-radius: 3px 0 0 3px;
}

.modal-close {
  text-align: right;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
}

.close-icon {
  color: black;
  font-weight: lighter !important;
}

.modal-description {
  text-align: justify;
  padding: 5px 5px 0 5px;
  margin-bottom: 20px;
  font-size: 12px;
}

.awssld__next {
  outline: none !important;
}

.awssld__prev {
  outline: none !important;
}

.loader-bar-color {
  color: black !important;
}

#portfolio {
  background: #d4d5f74f;
  padding-bottom: 5%;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.portfolio-item {
  max-width: 100%;
  margin-bottom: 15px;
  text-align: center;
}

.portfolio .portfolio-item .portfolio-item-caption {
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
  background-color: rgba(51, 51, 51, 0.9);
}

.portfolio .portfolio-item .portfolio-item-caption:hover {
  opacity: 1;
}

.portfolio
  .portfolio-item
  .portfolio-item-caption
  .portfolio-item-caption-content {
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  .portfolio .closeButtonResponsive {
    display: block;
  }
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
}

#skills {
  background: #1f1f1f;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 2%;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.section-title {
  padding-top: 5%;
  padding-bottom: 5%;
  font: 18px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  text-align: center;
}

.skill-icon {
  font-size: 180%;
  color: white;
  text-align: center;
  position: relative;
}

footer {
  background: #1f1f1f;
  min-height: 100px;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  color: white;
  position: relative;
  text-align: center;
}

footer a,
footer a:visited {
  color: #fff;
}

footer a:hover,
footer a:focus {
  color: #fff;
}

.social-links {
  margin-top: 50px;
  font-size: 22px;
}

.scroll-down-arrows {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 0 0 0;
  width: 30px;
  position: absolute;
  top: 85px;
}

.scroll-down-arrows:first-of-type {
  left: 20vw;
}

.scroll-down-arrows:last-of-type {
  right: 20vw;
}

.chevron {
  margin-top: -6px;
  position: relative;
  border: solid rgba(0, 0, 0, 0.7);
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

.chevron:nth-child(1) {
  animation: pulse 2s infinite;
  animation-delay: 0s;
}

.chevron:nth-child(2) {
  animation: pulse 2s infinite;
  animation-delay: 0.2s;
}

.chevron:nth-child(3) {
  animation: pulse 2s infinite;
  animation-delay: 0.4s;
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-8px, -8px);
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(8px, 8px);
  }
}

.section-transition {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: linear-gradient(to bottom, #fff 0%, transparent 100%);
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: linear-gradient(
      to top,
      rgba(213, 161, 233, 0.31) 0%,
      transparent 100%
    );
    z-index: 1;
  }
}

header,
#about,
#portfolio {
  transition: background-color 0.5s ease-in-out;
}

#skills {
  background: #1f1f1f;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 2%;
  position: relative;
  transition: all 0.5s ease-in-out;
}

#contact {
  background: #fff;
}
